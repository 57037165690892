<template>
<div class="page-layout -empty">
  <slot />
</div>
</template>

<style lang="scss">
.page-layout.-empty {
  background-color: $white;
}
</style>
